/* styles.css */

.form-container {
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

/* Radio Group Styles */
.radio-group {
  margin: 20px 0;
}

.MuiFormControlLabel-root {
  margin: 0 24px !important;
}

.MuiRadio-root {
  color: #999 !important;
}

.MuiRadio-root.Mui-checked {
  color: #1976d2 !important;
}

/* Select and Input Common Styles */
.countryPhone {
  width: 100%;
  padding: 12px 16px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  margin-bottom: 16px;
  text-align: center;
}

/* Select Specific Styles */
select.countryPhone {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
  cursor: pointer;
}

/* Input Specific Styles */
input.countryPhone {
  transition: border-color 0.2s ease;
}

/* Placeholder Styles */
.countryPhone::placeholder {
  color: #757575;
  opacity: 1;
  text-align: center;
}

/* Focus States */
.countryPhone:focus {
  outline: none;
  border-color: #1976d2;
  box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.1);
}

/* Input with Value */
.countryPhone:not(:placeholder-shown) {
  text-align: left;
}

/* Helper Text */
.helper-text {
  font-size: 12px;
  color: #666;
  margin-top: -12px;
  margin-bottom: 16px;
  text-align: center;
}

.helper-text.error {
  color: #d32f2f;
}

/* Error State */
.countryPhone.error {
  border-color: #d32f2f;
}

/* Title Styles */
.form-title {
  color: #333;
  text-align: center;
  margin-bottom: 24px;
  font-weight: 500;
}
/* Add to your existing styles.css */

.input-container {
  position: relative;
}

.character-count {
  position: absolute;
  right: 8px;
  bottom: -20px;
  font-size: 12px;
  color: #666;
}

.helper-text {
  margin-bottom: 24px; /* Increased to make room for character count */
}

/* Prevent number spinners in number inputs */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
