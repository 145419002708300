//Setting colors for UI
@import url("./dataPicker.scss");
$primary-color: #1a679c;
$font-color: #e4e4e4;

.btnColor {
  background-color: $primary-color !important;
  color: $font-color !important;
  font-weight: bold;
}
