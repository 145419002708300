html,
body {
  overscroll-behavior: none;
}

body {
  background-color: #f0f0f0;
}

button {
  background-color: #422560;
}
span {
  font-weight: bold;
}
p {
  color: #422560;
  font-size: 18px;
  font-weight: bolder;
}

.container {
  width: 80%;
  padding: 1%;
}

.input-field {
  margin-bottom: 10px;
}

.input-field label {
  display: block;
  margin-bottom: 5px;
}

.input-field input,
.input-field select {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.center-btn {
  text-align: center;
}

.center-btn button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.preloader {
  width: 100%;
  background-color: #1e0d31e3;
  height: 50px;
  position: absolute;
  height: 100%;
}

.loaderAnimation {
  color: #fff;
  display: flex;
  margin-top: 35px;
  display: flex;
  flex-direction: column;
}

.btnStepDisable {
  background-color: #9a9a9a;
}
.btnStepEnable {
  background-color: #422560;
}

/* Flex grid Rent Items */

/* .grid-rent-items {
}

.items-rent-card-info {
  width: 350px;
  height: 300px;
  background-color: #ccc;
  margin: 5;
  padding: 5;
} */

/* Flex grid */

.grid-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;
  background-color: #fff;
  margin-bottom: 50px;
}

.grid-item {
  flex: 0 0 20%; /* Two columns */
  box-sizing: border-box;
  padding: 10px;
}

.unselected {
  background-color: gray;
  color: white;
  font-size: 15px;
  font-weight: bold;
  width: 130px;
}

.selected {
  background-color: #422560;
  color: white;
  width: 100%;
}

.optionSelect {
  border: 4px dashed #422560;
  background-color: #fff;
}
.optionUnselect {
  border: none;
  background-color: #fafafa;
}

/* input fields */
.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-row {
  display: block;
  align-items: center;
  margin-bottom: 10px;
}

.input-row label {
  margin-bottom: 5px;
}

.input-row input {
  width: 90%;
}

.contInputs {
  width: 250px;
  height: 60px;
}

.contInputHour {
  width: 250px;
  height: 60px;
  margin-right: 1px;
  margin-left: 1px;
  border-left: 1px solid #673ab7;
  border-right: 1px solid #673ab7;
  /* background-color: #e4e4e4; */
}

#fecha {
  width: 90%;
  height: 25px;
}
#cantidad {
  width: 90%;
  height: 25px;
}

.countryPhone {
  height: 55px;
  width: 307px;
  padding: 5px;
  margin-top: 15px;
  margin-left: 16px;
}

#logosApp {
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin: 0 auto;
  margin-top: 10px;
}

#logosApp img {
  width: 35%;
  padding: 10px;
}

@media (max-width: 768px) {
  .input-container {
    flex-direction: column;
  }

  .input-row label {
    margin-bottom: 10px;
    width: 100%;
  }

  .input-row input {
    width: 100%;
  }
  .contInputHour {
    width: 90%;
    border-left: 0px solid #673ab7;
    border-right: 0px solid #673ab7;
  }
  .contInputs {
    width: 90%;
    /* background-color: #e4e4e4; */
  }
  #fecha {
    width: 90%;
    height: 15px;
  }
  #cantidad {
    width: 90%;
    height: 15px;
  }
  .countryPhone {
    height: 45px;
    width: 85%;
    margin-left: 3px;
    margin-bottom: 10px;
  }
  #logosApp img {
    width: 90%;
    padding: 10px;
  }
}

@media only screen and (min-width: 768px) {
  .countryPhone {
    height: 55px;
    width: 23%;
    padding: 5px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .countryPhone {
    height: 55px;
    width: 39%;
    padding: 5px;
    margin-left: 9px;
  }
}

.cantidadPersonas {
  width: 95%;
  height: 37px;
  margin-top: 2px;
}

@media (min-width: 768px) {
  .showAlertMessage {
    display: none;
  }
}
