.imageLogo {
  width: 30%;
}

/* Media query for mobile */
@media only screen and (max-width: 768px) {
  .imageLogo {
    width: 65%;
  }
}
