@import url("https://fonts.googleapis.com/css?family=Montserrat:300&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Montserrat, sans-serif;
  text-align: center;
}

input {
  margin-top: 1%;
  margin-left: 1%;
  font-family: Montserrat, sans-serif;
  padding: 2%;
  width: 92%;
  /* border-radius: 7em; */
  /* background-color: #ccd5fc; Green */

  /* border: 0; */
}

button {
  font-family: Montserrat, sans-serif;
  transition-duration: 0.7s;
  margin-top: 5%;
  height: 3em;
  width: 100%;
  color: white;
  background-color: #030303;
  border-radius: 1em;
  border: 0;
}

@media only screen and (max-width: 768px) {
  /* Styles for mobile phones and tablets */
  input {
    margin-top: 1%;
    margin-left: 1%;
    font-family: Montserrat, sans-serif;
    padding: 0.5%;
    width: 80%;
    padding: 2%;
  }
  button {
    font-family: Montserrat, sans-serif;
    transition-duration: 0.7s;
    margin-top: 5%;
    height: 3em;
    width: 90%;
    color: white;
    background-color: #030303;
    border-radius: 1em;
    border: 0;
  }
}

/* Media query for iPad and iPad mini */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Styles for iPad and iPad mini */
  input {
    margin-top: 1%;
    margin-left: 1%;
    font-family: Montserrat, sans-serif;
    padding: 1.2%;
    width: 36%;
  }
  button {
    font-family: Montserrat, sans-serif;
    transition-duration: 0.7s;
    margin-top: 3%;
    height: 3em;
    width: 40%;
    color: white;
    background-color: #030303;
    border-radius: 1em;
    border: 0;
  }
}

/* Media query for desktop */
@media only screen and (min-width: 1025px) {
  /* Styles for desktop */
  input {
    margin-top: 1%;
    margin-left: 1%;
    font-family: Montserrat, sans-serif;
    padding: 1.2%;
    width: 20%;
  }
  button {
    transition-duration: 0.7s;
    margin-top: 1%;
    height: 3em;
    width: 24%;
    color: white;
    background-color: #422560;
    border-radius: 1em;
    border: 0;
    font-weight: bold;
  }
}

/* container nav Buttons */

.containerNavBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #68616fd6;
  width: 100%;
  backdrop-filter: blur(2px);
}

@media (max-width: 600px) {
  .containerNavBtn {
    flex-direction: row;
    align-items: center;
  }

  .containerNavBtn button {
    width: "100%";
    margin-bottom: 8px;
  }
}

@media only screen and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
  /* Your custom styles for Android */
  .containerNavBtn {
    flex-direction: row;
    align-items: center;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  /* Your custom styles for iPhone */
  .containerNavBtn {
    flex-direction: row;
    align-items: center;
  }
}

/* Styles for Galaxy Fold */
@media (max-width: 280px) and (max-height: 653px) and (orientation: portrait) {
  /* Your custom styles for the Galaxy Fold in portrait orientation */
}

@media (max-width: 653px) and (max-height: 280px) and (orientation: landscape) {
  /* Your custom styles for the Galaxy Fold in landscape orientation */
}
