.form-container {
  display: flex;
  justify-content: center;
  margin: 0;
}

.form {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}

@media (max-width: 600px) {
  .form {
    max-width: 300px;
  }
}

.btnSend {
  background-color: #4e2c72;
  width: 100%;
  margin-top: 10px;
}

.btnsValidate {
  background-color: #4e2c72;
  width: 47%;
  margin-top: 10px;
}

button.btnSend:disabled {
  background-color: gray;
  color: white;
}

input[name="codigo"] {
  text-transform: uppercase;
}
