.Home {
  height: 100vh;
}
.titleHome {
  justify-content: center;
  color: #fff;
  position: absolute;
  align-content: center;
  text-align: center;
}

.containerTopBar {
  background-color: black;
}
.topBar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  background-image: linear-gradient(
    182deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgb(78, 44, 114) 78%
  );
  width: 100%;
  height: 105;
}

@media (max-width: 653px) and (max-height: 280px) and (orientation: landscape) {
  /* Your custom styles for the Galaxy Fold in landscape orientation */
}
@media only screen and (min-width: 1024px) {
  .imageLink {
    width: 90%;
    height: 100%;
    background-size: cover;
  }
  .containerImages {
    background-color: black;
    height: 650px;
  }
}

@media only screen and (min-width: 768px) {
  .imageDent {
    width: 55%;
    height: 100%;
    background-size: cover;
  }
}

@media only screen and (min-width: 1024px) {
  .imageDent {
    width: 100%;
    height: 100%;
    background-size: cover;
  }
}
